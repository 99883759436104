.navbar-links {
  display: flex;
  gap: 1rem;
}

.navbar-links button {
  font-size: 1.1rem;
  text-transform: none;
  border-radius: 25px;
  padding: 6px 16px;
  transition: all 0.3s ease; /* Add transition */
}

.navbar-links button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px); /* Add translateY animation */
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (max-width: 768px) {
  .navbar-transparent {
    background-color: #121212 !important;
    box-shadow: none !important;
  }
  .navbar-logo {
    display: none;
  }
  .navbar-links {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .navbar-logo {
    margin-right: auto;
  }
  .navbar-links {
    justify-content: center;
    flex: auto;
  }
}

.navbar-logo {
  text-decoration: none;
  color: inherit;
}
